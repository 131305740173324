import React from 'react'

import Layout from '../../../components/Layout'
import CaseStudyNav from '../../../components/CaseStudyNav'
import CaseStudyStepSummary from '../../../components/CaseStudyStepSummary'
import CaseStudyStepCard from '../../../components/CaseStudyStepCard'
import CaseTwoPodcast from '../../../media/2018-10-22-PODCAST_Medication_Management.mp3'
import CaseTwoTranscript from '../../../media/2018-10-30-PODCAST_Medication_Management_transcript.pdf'

export default class DiagnosticCaseStudy extends React.Component {
  // Handles fade-in by detecing when the component fully mounts, then doing fade-in
  componentDidMount() {
    document.getElementById('aga-case-anchor').classList.add('aga-fade-in')
  }

  render() {
    return (
      <div>
        <Layout>
          <CaseStudyNav 
            linkOneClass="aga-casestudy-link"
            linkTwoClass="aga-casestudy-link active"
            linkThreeClass="aga-casestudy-link"
          />

          <div id="aga-case-anchor">

            {/* Part 2 */}
            <div>
              <CaseStudyStepSummary
                part="Case Two"
                partSubtitle="Medication and Management - Gastroenterology"
                partSummary={[
                  <p key="CVPOICF09E09JADF">You examine Jennifer after she is referred to you by her primary-care physician. After reviewing her history and labs, you order an MRI and find mild thickening of the last 15 centimeters of her terminal ileum. These imaging findings lead Jennifer to worry that she has colon cancer. You assure her that the findings could be due to a number of causes and that a colonoscopy will provide the opportunity to obtain tissue samples of the affected area to make a diagnosis. </p>,
                  <p key="98ADF9JK2KJR292J">Jennifer returns to your office after the colonoscopy results are in, telling you that she had a terrible time with the prep—nausea, vomiting, even feeling at one point like she would pass out. She says she never wants to do that again. You explain to her that her colon was entirely normal, and that there was no cancer, but the biopsies were consistent with a diagnosis of Crohn’s disease.</p>
                ]}
                mediaTitle="Episode Two"
                mediaSubtitle="IBD Medication Management"                
                podcast={ CaseTwoPodcast } 
                podcastName="Episode-Two-IBD-Medication-Management.mp3"
                podcastTranscript={ CaseTwoTranscript }
                podcastTranscriptName="Episode-Two-IBD-Medication-Management-transcript.pdf"       
              />
            </div>

            <article className="aga-cs-container-short" id="part-2">
              <h2 className="aga-cs-question-title">Case 2: Steps</h2>
              <section className="aga-card-row">
                <CaseStudyStepCard 
                  title="Step 4"
                  body="What treatment and management plan do you prescribe for Jennifer?"
                  footer="View Step"
                  link="/steps/step-4"
                />
                <CaseStudyStepCard 
                  title="Step 5"
                  body="What changes, if any, would you make in Jennifer’s medication at this point? What information should you convey to this patient’s primary care physician?"
                  footer="View Step"
                  link="/steps/step-5"
                />
              </section>
            </article>         
          </div>
        </Layout>
      </div>
    )
  }
}
